import { animateElement } from './animations';

function initIntersectionObserver() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateElement(entry.target);
        }
      });
    },
    {
      root: document.body,
      rootMargin: '0px',
      threshold: 0.1,
    }
  );

  const ovservedElementIds = [];
  const observedElementClasses = [
    '.hero-content',
    '.intro',
    '.card-left',
    '.card-right',
    '.revaccordion',
  ];

  ovservedElementIds.forEach((selector) => {
    const el = document.querySelector(selector);
    if (el) {
      observer.observe(el);
    }
  });

  observedElementClasses.forEach((selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      observer.observe(el);
    });
  });
}

export { initIntersectionObserver };
