function initPreloader() {
  const preloader = document.querySelector('#page-preloader');
  if (!preloader) return;

  // Check if the http headers has a referrer and is not an external domain
  if (
    document.referrer &&
    document.referrer.indexOf(window.location.hostname) !== -1
  ) {
    preloader && preloader.classList.add('hidden');
  }
}

export { initPreloader };
