function initMenuIcon() {
  const toggleMenuIcon = document.querySelector('#toggle-menu-icon svg');
  if (!toggleMenuIcon) return;

  jQuery(document).on('elementor/popup/show', (event, id, instance) => {
    if (id === 229) {
      // Mobile menu
      toggleMenuIcon.classList.add('open');
    }
  });

  jQuery(document).on('elementor/popup/hide', (event, id, instance) => {
    if (id === 229) {
      // Mobile menu
      toggleMenuIcon.classList.remove('open');
    }
  });
}

export { initMenuIcon };
