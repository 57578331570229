const { __ } = wp.i18n;

function initReadMoreComponent() {
  const wrappers = document.querySelectorAll('.read-more-wrapper');
  wrappers &&
    wrappers.forEach((wrapper) => {
      const expand = wrapper.querySelector('.read-more-expand');
      const button = wrapper.querySelector('.read-more-button a');
      const buttonText = button.querySelector('.elementor-button-text');
      button &&
        button.addEventListener('click', (ev) => {
          ev.preventDefault();
          const isExpanded = expand.classList.contains('expanded');
          if (isExpanded) {
            expand.classList.remove('expanded');
            button.classList.remove('expanded');
            buttonText.textContent = __('Read More');
          } else {
            expand.classList.add('expanded');
            button.classList.add('expanded');
            buttonText.textContent = __('Read Less');
          }
        });
    });
}
export { initReadMoreComponent };
