import { loadGradientTop } from './components/hero';
import { initPreloader } from './components/preloader';
import { initMenuIcon } from './layout/header';
import { initIntersectionObserver } from './base/intersectionObserver';
import { initReadMoreComponent } from './components/read-more';
import { initScrollTo, isHomePage } from './base/utils';
import { animateRevoroSlider } from './base/animations';

const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
const isReducedMotion = mediaQuery && mediaQuery.matches;

document.addEventListener('DOMContentLoaded', () => {
  //const isHome = isHomePage();
  if (!isReducedMotion) {
    // If the preloader will be displayed
    if (
      !document.referrer ||
      (document.referrer &&
        document.referrer.indexOf(window.location.hostname) === -1)
    ) {
      // Init animations after proload screen
      setTimeout(initIntersectionObserver, 3000);
    } else {
      initIntersectionObserver();
    }
    initScrollTo();
  }
  initPreloader();
  initReadMoreComponent();
});

window.addEventListener('load', () => {
  loadGradientTop();
  if (!isReducedMotion) {
    animateRevoroSlider();
  }
});

// JQuery
jQuery(document).ready(function () {
  initMenuIcon();
});
