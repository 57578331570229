function windowScrollTo(element) {
  let target = null;
  if (element === '#') {
    target = document.querySelector('body');
  } else {
    target = document.querySelector(element);
  }

  if (target) {
    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

function initScrollTo() {
  const links = document.querySelectorAll('.scroll-to');
  links &&
    links.forEach((link) => {
      // Check if the link is an acnhor tag
      if (link.tagName !== 'A') {
        link = link.querySelector('a');
      }

      link.addEventListener('click', (e) => {
        e.preventDefault();
        const href = link.getAttribute('href');
        windowScrollTo(href);
      });
    });
}

function isHomePage() {
  return document.body.classList.contains('home');
}

export { initScrollTo, isHomePage };
