let animated = [];
const animationDictionary = {
  'hero-content': animateHero,
  'pre-footer': animatePreFooter,
  intro: animateIntro,
  intro: animateIntro,
  intro: animateIntro,
  'card-left': animateCardRight,
  'card-right': animateCardRight,
  revaccordion: animateRevoroAccordion,
};

function gsapDefaults() {
  gsap.defaults({
    duration: 0.7,
    ease: 'power1.out',
  });
}

function animateElement(element) {
  // Do nothing if gsap is not available
  if (!gsap) {
    console.log('gsap is not available');
    return;
  }

  // Do nothing if already animated
  if (animated.includes(element)) return;

  // Set gsap defaults
  gsapDefaults();

  // Show on animate
  showOnAnimate(element);

  const { id } = element;
  const classes = element.className.split(' ');
  const animationIndex = classes.find((c) => animationDictionary[c]);

  if (animationDictionary[id]) {
    animationDictionary[id](element);
  } else if (animationDictionary[animationIndex]) {
    animationDictionary[animationIndex](element);
  } else {
    element.classList.remove('show-on-animate');
  }

  animated.push(element);
}

function showOnAnimate(element) {
  // Set element to visible if gsap is not available
  if (!gsap) {
    const hiddenElements = document.querySelectorAll('.show-on-animate');
    hiddenElements &&
      hiddenElements.forEach((el) => {
        el.classList.remove('show-on-animate');
      });
    return;
  }

  gsap.from(element, {
    autoAlpha: 0,
    duration: 0,
  });
}

function animateHero(element) {
  const titleTag = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].filter((el) =>
    element.querySelector(el)
  )[0];
  let title = element.querySelector(titleTag);
  const titleSpans = title.querySelectorAll('span');
  titleSpans.length && (title = titleSpans);

  const divider = element.querySelector('.divider');
  const copy = element.querySelector('.copy');
  const cta = element.querySelector('.cta');

  let tl = gsap.timeline();

  title &&
    tl.fromTo(
      title,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.2,
        duration: 0.3,
        ease: 'power2.inOut',
      }
    );

  title && tl.fromTo(title, { x: -50 }, { x: 0, stagger: 0.2 }, '<');

  divider && animateDivider(divider, tl);

  copy && tl.fromTo(copy, { y: 20, opacity: 0 }, { y: 0, opacity: 1 });

  cta && tl.fromTo(cta, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.5');
}

function animateIntro(element) {
  const title = element.querySelector('h2');
  const divider = element.querySelector('.divider');
  const copy = element.querySelector('.copy');
  const cta = element.querySelector('.cta');

  let tl = gsap.timeline();

  title && tl.fromTo(title, { y: 50, opacity: 0 }, { y: 0, opacity: 1 });

  divider && animateDivider(divider, tl);

  copy && tl.fromTo(copy, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.2');

  cta && tl.fromTo(cta, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.4');
}

function animateCardRight(element) {
  const titleTag = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].filter((el) =>
    element.querySelector(el)
  )[0];
  let title = element.querySelector(titleTag);
  const titleSpans = title.querySelectorAll('span');
  titleSpans.length && (title = titleSpans);

  const divider = element.querySelector('.divider');
  const copy = element.querySelectorAll('.copy');
  const cta = element.querySelector('.cta');

  let tl = gsap.timeline();

  title &&
    tl.fromTo(
      title,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.2,
        duration: 0.3,
        ease: 'power2.inOut',
      }
    );

  title && tl.fromTo(title, { x: -20 }, { x: 0, stagger: 0.2 }, '<');

  divider && animateDivider(divider, tl);

  const copy0 = copy[0];
  const copy1 = copy[1];

  let copyHeading = null;
  let li = null;
  let p = null;
  if (copy[1]) {
    const copyHeadingTag = ['h2', 'h3', 'h4', 'h5', 'h6'].filter((el) =>
      copy[1].querySelector(el)
    )[0];
    copyHeading = copy[1].querySelector(copyHeadingTag);
    li = copy[1].querySelectorAll('li');
    p = copy[1].querySelector('p');
  }

  copy0 &&
    tl.fromTo(
      copy0,
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.2, stagger: 0.1 },
      '<'
    );

  copyHeading &&
    tl.fromTo(
      copyHeading,
      { opacity: 0 },
      { opacity: 1, ease: 'power2.inOut' },
      '-=0.5'
    );

  li &&
    tl.fromTo(
      li,
      { opacity: 0 },
      { opacity: 1, stagger: 0.2, ease: 'power2.inOut' },
      '-=0.5'
    );

  p &&
    tl.fromTo(
      p,
      { x: -20, opacity: 0 },
      { x: 0, opacity: 1, delay: 0.2 },
      '-=0.5'
    );

  cta &&
    tl.fromTo(
      cta,
      { x: -20, opacity: 0 },
      { x: 0, opacity: 1, delay: 0.3 },
      '-=0.5'
    );
}

function animatePreFooter(element) {
  gsap.fromTo(
    element,
    { opacity: 0 },
    { opacity: 1, ease: 'power2.inOut', duration: 0.4 }
  );
}

function animateDivider(divider, tl) {
  if (!divider) return;

  if (tl) {
    tl.from(divider, { width: '0px', ease: 'back.inOut' }, '-=0.5');
  } else {
    gsap.from(divider, { width: '0px', ease: 'back.inOut' });
  }
}

function animateRevoroAccordion(element) {
  const items = element.querySelectorAll('.revaccordion__item');
  items &&
    gsap.fromTo(
      items,
      { opacity: 0, x: '100vw' },
      {
        opacity: 1,
        x: '0',
        stagger: 0.1,
        duration: 0.3,
        ease: 'power1.inOut',
      }
    );
}

function animateRevoroSlider() {
  animateSlide();
  // Swiper instances
  const { sliderLeftInstances } = window;
  sliderLeftInstances &&
    sliderLeftInstances.forEach((slider) => {
      slider.on('slideChange', function () {
        animateSlide();
      });
    });
}

function animateSlide() {
  const activeSlideRight = document.querySelector(
    '.revslider__right .swiper-slide-active'
  );
  if (activeSlideRight) {
    const divider = activeSlideRight.querySelector('.divider');
    const ps = activeSlideRight.querySelectorAll('p');
    const ul = activeSlideRight.querySelectorAll('ul');

    const tl = gsap.timeline();

    if (divider) {
      gsap.set(divider, { width: '50%' });
      tl.fromTo(
        divider,
        { width: '0px' },
        { width: '50%', ease: 'back.inOut' }
      );
    }

    if (ps) {
      tl.fromTo(
        ps,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.2, ease: 'power1.inOut' },
        '-=0.5'
      );
    }

    if (ul) {
      tl.fromTo(
        ul,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.2, ease: 'power1.inOut' },
        '-=0.5'
      );
    }
  }
}

export { animateElement, animateRevoroSlider };
